import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto'
    }}>
      <Title mdxType="Title">Pegasys protocol disclaimer
      </Title>
      <p>{`Pegasys is a decentralized peer-to-peer protocol that people can use to create liquidity and trade ERC-20 tokens. The Pegasys protocol is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on the Syscoin Blockchain. Your use of the Pegasys protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Pegasys protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Pegasys protocol, you should review the relevant documentation to make sure you understand how the Pegasys protocol works. Additionally, just as you can access email protocols such as SMTP through multiple email clients, you can access the Pegasys protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.`}</p>
      <p>{`AS DESCRIBED IN THE PEGASYS PROTOCOL LICENSES, THE PEGASYS PROTOCOL IS PROVIDED `}{`”`}{`AS IS`}{`”`}{`, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Pegasys Labs developed much of the initial code for the Pegasys protocol, it does not provide, own, or control the Pegasys protocol, which is run by smart contracts deployed on the Syscoin blockchain. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the PSYS governance token. No developer or entity involved in creating the Pegasys protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the Pegasys protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      